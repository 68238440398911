.App {
	text-align: center;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.App .container {
	max-width: 50%;
}

@media screen and (max-width: 767px) {
	.App .container {
		max-width: 90%;
	}
}
